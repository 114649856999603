const headers = {
  promoCodesUpload: 'Загрузка промокодов',
  promoCheckUpload: 'Загрузка чека',
  promoProducts: 'Продукция',
  whereBuy: 'Где купить',
  reviews: 'Отзывы',
  help: 'Помощь',
  account: 'Личный кабинет',
  businessChallenge: 'Бизнес-задачи',
  profile: 'Профиль',
  changePassword: 'Изменить пароль',
  faq: 'Вопросы и ответы',
  news: 'Новости',
  personalData: 'Персональные данные',
  signIn: 'Авторизация',
  signUp: 'Регистрация',
  restorePassword: 'Восстановить пароль',
  feedback: 'Обратная связь',
  newPassword: 'Смена пароля',
  uploadMethod: 'Способ загрузки',
  confirmPhone: 'Подтверждение номера',
  handInsertCheck: 'Ручной ввод текста',
  photoUploadCheck: 'Загрузить фото чека',
  qrUploadCheck: 'Отсканировать QR код',
  sendReview: 'Оставить отзыв',
  emailVerification: 'Ваш Email подтвержден',
  main: 'Главная',
  products: 'Продукция',
  uploadFiles: 'Перетащите файл сюда',
  myCodes: 'Мои коды',
  myChecks: 'Мои чеки',
  code: 'Код',
  uploadDate: 'Дата загрузки',
  uploadDateTime: 'Дата и время загрузки',
  status: 'Статус',
  rejectReason: 'Причина отклонения',
  codeIsEmpty: 'Загруженные коды отсутствуют',
  checkIsEmpty: 'Загруженные чеки отсутствуют',
  consent: 'Подпись',
  checkId: 'ID чека',

  review: (date: string | null | undefined) =>
    `Отзыв${date ? ` от ${date}` : ''}`,
}

export default headers
