import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import TaxConsentForm from '$components/form/consent/TaxConsentForm'

import s from './style.module.scss'

const TaxConsentPopup = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.consent}
      </h2>
      <p className={s['text']}>
        В открывшемся окне на экране мобильного телефона пальцем или курсором на
        экране компьютера повторите свой обычный автограф и нажмите «Подписать»
      </p>
      <TaxConsentForm />
    </div>
  )
}

export default TaxConsentPopup
