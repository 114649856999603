import React, { PropsWithChildren } from 'react'

export const Rubik: React.FC<PropsWithChildren<any>> = ({
  children,
  ...props
}) => (
  <span {...props} style={{ fontFamily: 'Rubik' }}>
    {children}
  </span>
)
