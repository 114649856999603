import { NextRouter } from 'next/router'

export function goToAccountRoom(router: NextRouter, withRegPopup?: boolean) {
  router.push(
    {
      pathname: decodeURI(
        `/account/business-challenge${withRegPopup ? '/?reg=true' : ''}`,
      ),
    },
    `/account/business-challenge${withRegPopup ? '/?reg=true' : ''}`,
  )
}
