import React from 'react'

import Dulux from '../../../assets/img/brands/dulux.png'
import Hammerite from '../../../assets/img/brands/hammerite.png'
import Marshal from '../../../assets/img/brands/marshal.png'
import Pinotex from '../../../assets/img/brands/pinotex.png'

import classes from './Footer.module.scss'

const brandList = [
  {
    img: Dulux.src,
    name: 'Dulux',
    href: 'https://www.dulux.ru/',
  },
  {
    img: Pinotex.src,
    name: 'Pinotex',
    href: 'https://www.pinotex.ru/',
  },
  {
    img: Hammerite.src,
    name: 'Hammerite',
    href: 'https://www.hammerite.ru/',
  },
  {
    img: Marshal.src,
    name: 'Marshal',
    href: 'https://marshall-paints.ru/',
  },
]

const Footer: React.FC = () => (
  <footer className={classes['footer']}>
    <ul className={classes['brand-list']}>
      {brandList.map(({ img, name, href }) => (
        <li key={name} className={classes['brand-list__item']}>
          <a target="_blank" title={name} href={href} rel="noreferrer">
            <img alt="text" src={img} />
          </a>
        </li>
      ))}
    </ul>
  </footer>
)

export default Footer
