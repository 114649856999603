import { PropsWithChildren, useEffect } from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { useAppSelector } from '$store/hooks'

import useFingerprint from '$hooks/useFingerprint'

import ViewportService from '$services/ViewportServices/ViewportServices'

import Favicon from '$components/Favicon'
import BodyHtml from '$components/helpers/html/BodyHtml'
import HeadHtml from '$components/helpers/html/HeadHtml'
import MadeWithPromoBuilding from '$components/helpers/html/MadeWithPromobuilding'
import PromoTrap from '$components/helpers/trap/PromoTrap'
import PopupManagement from '$components/popups/PopupManagement'

import MetricsWrapper from '$/other/metrix/MetricsWrapper'
import VKPixel from '$/other/VKPixel/VKPixel'

type MainAppProps = PropsWithChildren

export default function MainApp({ children }: MainAppProps) {
  const setFingerprint = useFingerprint()
  // const dispatch = useDispatch()

  useEffect(() => {
    setFingerprint()
  }, [setFingerprint])

  // const { query } = useRouter()
  // const popupId = query['popupid']

  // useEffect(() => {
  //   if (!sessionStorage.getItem('extension') && !popupId) {
  //     dispatch(
  //       showPopup({
  //         title: 'Уважаемые участники!',
  //         message: (
  //           <p>
  //             Все заказы Виртуальной карты MyGift и&nbsp;Виртуальной Карты
  //             MyGift Mobile, сделанные с <b>14.06.2024</b>&nbsp;на сайте{' '}
  //             <a
  //               href="https://provse.ru/"
  //               target="_blank"
  //               rel="noreferrer noopener"
  //             >
  //               provse.ru
  //             </a>{' '}
  //             были отменены в&nbsp;связи с&nbsp;техническими сложностями. Баллы
  //             за&nbsp;них возвращены Вам в&nbsp;Личном кабинете на&nbsp;сайте{' '}
  //             <a
  //               href="https://provse.ru/"
  //               target="_blank"
  //               rel="noreferrer noopener"
  //             >
  //               provse.ru
  //             </a>
  //             . Просьба зайти в&nbsp;личный кабинет и&nbsp;сделать там новый
  //             заказ любого из&nbsp;наших партнеров.
  //             <br />
  //             <br /> Обращаем внимание, что при повторном заказе Виртуальной
  //             карты MyGift или Виртуальной Карты MyGift Mobile, их&nbsp;доставку
  //             стоит ожидать не&nbsp;ранее <b>16&nbsp;июля</b> там&nbsp;же,
  //             в&nbsp;личном кабинете{' '}
  //             <a
  //               href="https://provse.ru/"
  //               target="_blank"
  //               rel="noreferrer noopener"
  //             >
  //               provse.ru
  //             </a>
  //             . Все остальные сертификаты будут доставляться в&nbsp;прежние
  //             сроки, без задержек.
  //             <br />
  //             <br /> Просим прощения за&nbsp;доставленные неудобства!
  //             <br />
  //             Команда Программы &laquo;Выгода&nbsp;в&nbsp;банке&raquo;.
  //           </p>
  //         ),
  //       }),
  //     )
  //     sessionStorage.setItem('extension', 'true')
  //   }
  // }, [])

  const recaptcha = useAppSelector((store) => store.config.site?.recaptcha)

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        recaptcha?.version === 'v3' ? recaptcha?.site_key ?? '' : ''
      }
    >
      <HeadHtml />
      <Favicon />
      <PopupManagement />
      <PromoTrap>
        <ViewportService>{children}</ViewportService>
      </PromoTrap>
      <BodyHtml />
      <MadeWithPromoBuilding />
      <MetricsWrapper />
      <VKPixel />
    </GoogleReCaptchaProvider>
  )
}
