import classNames from 'classnames'
import Link from 'next/link'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { UserType } from '$store/slices/config/types/user/userType'
import { showPopup } from '$store/slices/popup'

import Button from '$components/ui/buttons/RootButton/Button'

import Gift from './Gift.svg?component'
import Option from './Option.svg?component'
import Warn from './warn.svg?component'

import s from './OptionLink.module.scss'

const OptionsLink = () => {
  const dispatch = useDispatch()

  const { phoneIsVerified, emailIsVerified } = useAppSelector(
    (store) => store.user,
  ) as UserType

  const order = useAppSelector(
    ({ config }) => config.rewards.account?.orders[0],
  )

  return (
    <div className={s['option-links-block']}>
      {(!phoneIsVerified || !emailIsVerified) && <Warn className={s['warn']} />}
      {order && (
        <button
          onClick={() => {
            dispatch(
              showPopup({
                title: 'Поздравляем!',
                message: (
                  <div>
                    <p>Вы стали победителем розыгрыша и получили подарок</p>
                    <div className={s['reward']}>
                      {order.reward.name} баллов
                    </div>
                    <div className={s['action']}>
                      {order.status !== 'delivered' && (
                        <Button
                          onClick={() => {
                            dispatch(
                              showPopup({
                                popupId: 'receipt',
                                popupData: {
                                  rewardId: order.reward.id,
                                  orderId: order.id,
                                  isClaimed: true,
                                },
                              }),
                            )
                          }}
                        >
                          Получить
                        </Button>
                      )}
                      {order.status === 'delivered' && (
                        <a
                          target="_blank"
                          href={order.product_payload.provse_link ?? ''}
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            display: 'block',
                            marginTop: 10,
                          }}
                        >
                          {`Сертификат "Провсё"`}
                        </a>
                      )}
                    </div>
                  </div>
                ),
              }),
            )
          }}
          className={classNames(
            s['gift'],
            order.status === 'approved' && s['gift-pulse'],
          )}
        >
          <Gift />
        </button>
      )}
      <Link href="/account/settings">
        <Option />
      </Link>
    </div>
  )
}

export default OptionsLink
