import { useCallback } from 'react'

import withClientJs from '$api/fingerprint/clientjs'
import withFingerprintJsPro from '$api/fingerprint/fingerprintjs-pro'

import { useAppSelector } from '$store/hooks'

export default function useFingerprint() {
  const { fingerprint_api_key: fingerprintApiKey } = useAppSelector(
    (state) => state.config.promo,
  )

  return useCallback(() => {
    withClientJs()

    if (fingerprintApiKey) {
      withFingerprintJsPro({ apiKey: fingerprintApiKey })
    }
  }, [fingerprintApiKey])
}
