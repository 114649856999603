import React, { useMemo, useEffect } from 'react'

import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import kladrApi from '$api/kladr/kladrApi'
import pbApi from '$api/promobuilding/pbApi'

import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'
import popupsID from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { signIn } from '$store/slices/user/thunks/user'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'
import useValidators from '$hooks/useValidators/useValidators'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import AsyncSelectField from '$components/form/formik/fields/AsynchSelectField/AsynchSelectField'
import CheckBoxField from '$components/form/formik/fields/CheckBoxField'
import SyncSelectField from '$components/form/formik/fields/SynchSelectField/SynchSelectField'
import TextInputField from '$components/form/formik/fields/TextInputField'
import initializeValue from '$components/form/formik/utils/InitializeFormikValues'
import validatorCombiner, {
  ValidatorType,
} from '$components/form/validators/validatorCombiner'
import BottomLinks from '$components/ui/forms/BottomLinks'

import { popupLink } from '$utils/link'
import { goToAccountRoom } from '$utils/navigation/account'

const SignUpForms: React.FC<Props> = ({
  viewConfig,
  phoneConfirmConfig: { verify_required },
  signInAction,
  showPopupAction,
}) => {
  const { isRequired, latinAndDigit, isValidEmail, maxLength, minLength } =
    useValidators()

  const motivationalProgramConfig = useAppSelector(
    (store) => store.config.auth?.motivationalProgramConfig,
  )

  const authField = useAppSelector((store) => store.config.auth.authFields)

  const isReferral = useAppSelector(
    (store) => store.config.auth?.referralProgramConfig?.isEnabled,
  )
  const { code } = useAppSelector((store) => store.config.inviteCode)

  const router = useRouter()
  const dictionary = useLanguageDictionary()
  const postPromoHandler = usePromoStatus(true, {
    title: 'Регистрация окончена',
  })

  const inviteSharingLink = useAppSelector(
    (state) => state.config.motivational_program?.sharingLink,
  )

  const inviteQuery = inviteSharingLink?.queryParamName
    ? inviteSharingLink?.queryParamName
    : 'invite'
  const { [inviteQuery]: invite } = router.query

  const inviteCode = invite
  const initialValues = useMemo(() => {
    const baseValues = initializeValue(
      [
        'email',
        'password',
        'password_confirmation',
        ...authField.map(({ name }) => name),
      ],
      {
        ...authField.reduce((acc, field) => {
          if (field.type === 'checkbox') {
            acc[field.name] = field.checked
          }
          return acc
        }, {} as any),
      },
    )()

    const motivationalProgramValues =
      motivationalProgramConfig &&
      motivationalProgramConfig.invite_code_show_config ===
        'inside_registration'
        ? { motivational_invite_code: inviteCode ?? '' }
        : {}

    return {
      ...baseValues,
      ...motivationalProgramValues,
    }
  }, [authField, motivationalProgramConfig, inviteCode])

  useEffect(() => {
    if (postPromoHandler()) {
      router.push('/')
    }
  }, [postPromoHandler, router])

  const successSignUpHandler = useMemo(
    () => (value: typeof initialValues) => () => {
      signInAction({
        [authFieldCollection.email.name]: value.email,
        [authFieldCollection.password.name]: value.password,
      }).then(() => {
        if (verify_required) {
          showPopupAction({
            popupId: popupsID.confirm_phone,
            popupData: {
              phone: `7${value.phone}`,
            },
          })
          goToAccountRoom(router, false)
        } else {
          showPopupAction({
            title: dictionary.messages.thanks,
            message: dictionary.messages.successRegistration,
            type: 'success',
          })
          goToAccountRoom(router, false)
        }
      })
    },
    [router, showPopupAction, signInAction],
  )

  const signUp = useFormSubmitHandler<typeof initialValues>(
    [
      (values, { setSubmitting, setFieldError }) => {
        if (
          values[authFieldCollection.password.name] !==
          values.password_confirmation
        ) {
          setFieldError(
            'password_confirmation',
            dictionary.errors.passwordsNotMatch,
          )
          setSubmitting(false)
          return
        }
        return values
      },
    ],
    (value) => {
      const phone = value.phone ? { phone: `7${value.phone}` } : {}
      if (
        motivationalProgramConfig?.invite_code_show_config ===
        'before_registration'
      ) {
        Object.assign(value, { motivational_invite_code: code })
      }

      return pbApi
        .signUp({ ...value, ...phone })
        .then(successSignUpHandler(value))
    },
  )

  return (
    <>
      <FormikConstructor
        initialValues={initialValues}
        onSubmit={signUp}
        name="signup"
        submitButtonName="Регистрация"
        dataTest="registration-button"
      >
        <>
          {motivationalProgramConfig &&
            motivationalProgramConfig.invite_code_show_config ===
              'inside_registration' && (
              <TextInputField
                name="motivational_invite_code"
                validate={validatorCombiner([isRequired, latinAndDigit])}
                placeholder={dictionary.fields.inviteCode}
              />
            )}
          {isReferral && (
            <TextInputField
              name="referral_invite_code"
              placeholder={dictionary.fields.referral_code}
              data-test="referral-code-input"
              type="text"
            />
          )}
        </>
        {authField.map((field) => {
          const validators: ValidatorType[] = []
          field.rules?.forEach((rules) => {
            if (rules === 'required') {
              validators.push(isRequired)
            }
            if (typeof rules === 'string') {
              if (rules.includes('min')) {
                const val = rules.split(':')[1]
                validators.push(minLength(val))
              }
              if (rules.includes('max')) {
                const val = rules.split(':')[1]
                validators.push(maxLength(val))
              }
            }
          })

          switch (field.type) {
            case 'email': {
              return (
                <TextInputField
                  name={field.name ?? authFieldCollection.email.name}
                  type={authFieldCollection.email.type}
                  data-test="email-input"
                  placeholder={field.label ?? dictionary.fields.email}
                  validate={validatorCombiner([
                    isRequired,
                    isValidEmail,
                    maxLength(255),
                  ])}
                  inputMode="email"
                />
              )
            }
            case 'phone': {
              return (
                <TextInputField
                  name={authFieldCollection.phone.name}
                  data-test="phone-number-input"
                  type={authFieldCollection.phone.type}
                  inputMode="tel"
                  placeholder={field.label ?? dictionary.fields.phone}
                  mask={authFieldCollection.phone.mask}
                  label={authFieldCollection.phone.label}
                  validate={validatorCombiner([
                    ...validators,
                    minLength(10, /[\D]+/g, 1),
                  ])}
                />
              )
            }
            case 'password': {
              return (
                <>
                  <TextInputField
                    name={authFieldCollection.password.name}
                    data-test="password-input"
                    placeholder={field.label ?? dictionary.fields.password}
                    type={authFieldCollection.password.type}
                    validate={validatorCombiner([isRequired, minLength(8)])}
                  />
                  <TextInputField
                    name="password_confirmation"
                    placeholder={
                      field.label
                        ? `Повторите ${field.label}`
                        : dictionary.fields.repeat_password
                    }
                    data-test="confirm-password-input"
                    type={authFieldCollection.password.type}
                    validate={validatorCombiner([isRequired, minLength(8)])}
                  />
                </>
              )
            }
            case 'text': {
              return (
                <TextInputField
                  key={field.name}
                  name={field.name ?? ''}
                  placeholder={field.label ?? ''}
                  validate={validatorCombiner(validators)}
                />
              )
            }
            case 'select': {
              const options = field.options.map((item) => ({
                label: item,
                value: item,
              }))
              return (
                <SyncSelectField
                  options={options}
                  key={field.name}
                  name={field.name}
                  placeholder={field.name ?? ''}
                  validate={validatorCombiner(validators)}
                />
              )
            }
            case 'checkbox': {
              return (
                <CheckBoxField
                  key={field.name}
                  name={field.name}
                  validate={validatorCombiner(validators)}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: field.label ?? '' }}
                  />
                </CheckBoxField>
              )
            }
            case 'region': {
              return (
                <AsyncSelectField
                  key={field.name}
                  name={field.name ?? authFieldCollection.region.name}
                  loadOptions={kladrApi.getRegion}
                  data-test="region-input"
                  placeholder={field.label ?? dictionary.fields.region}
                  validate={validatorCombiner(validators)}
                />
              )
            }
            case 'city': {
              return (
                <AsyncSelectField
                  key={field.name}
                  name={field.name}
                  loadOptions={kladrApi.getCity}
                  data-test="region-input"
                  placeholder={field.label ?? dictionary.fields.city}
                  validate={validatorCombiner(validators)}
                />
              )
            }
            case 'number': {
              return (
                <TextInputField
                  key={field.name}
                  name={field.name}
                  placeholder={field.name ?? ''}
                  type="number"
                  inputMode={field.integer ? 'numeric' : 'decimal'}
                  validate={validatorCombiner(validators)}
                />
              )
            }
            default: {
              return null
            }
          }
        })}
      </FormikConstructor>
      <BottomLinks
        links={[
          {
            href:
              viewConfig === 'modal'
                ? popupLink(popupsID.sign_in)
                : routePath.auth,
            name: dictionary.links.signIn,
          },
          {
            href: routePath.restorePassword,
            name: dictionary.links.restorePassword,
          },
        ]}
      />
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    authFieldList: state.config.auth?.authFields!,
    phoneConfirmConfig: state.config.auth?.phoneConfirmConfig!,
    customInputConfig: state.config.auth?.customInputConfig!,
    promoRulesOption: state.config.auth?.promoRulesValue!,
    viewConfig: state.config.auth?.viewTemplate!,
  }),
  (dispatch: AppDispatch) => ({
    signInAction: async (value: any) => dispatch(signIn(value)).unwrap(),
    showPopupAction: (popup: PopupConfig) => {
      dispatch(showPopup(popup))
    },
  }),
)

export default connector(SignUpForms)
