import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import { FieldInputProps, useFormikContext } from 'formik'
import { FileDrop } from 'react-file-drop'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import CloseIcon from '$components/icons/close/CloseIcon'

import { FileFieldInterface } from '$form/fields/formikField/type/FormikFieldType'

const setFileNamesStr = (val: FileList) => {
  let fileNameStr = ''
  for (let i = 0; i < val.length; i++) {
    fileNameStr += ` ${val[i].name}`
  }
  return fileNameStr
}

const btnWrapperClasses = classNames('btn-wrapper')
const fileNamesClasses = classNames('file-names')
const attachIconClasses = classNames('attach-icon')
const dragAreaClasses = classNames('drag-area')
const dragWrapperClasses = classNames('drag-wrapper')
const uploadRulesClasses = classNames('upload-rules')

const AllowFormat: React.FC<{
  allowFileFormat?: string
  maxFileSize?: string
}> = ({ allowFileFormat = '', maxFileSize = '' }) => (
  <span className="option-label">
    {allowFileFormat && `Допустимые форматы: ${allowFileFormat}.`}{' '}
    {maxFileSize && `Максимальный размер: ${maxFileSize}мб.`}
  </span>
)

export const UploadButton: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
}) => (
  <div className={btnWrapperClasses}>
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className="button"
      data-test="upload-button"
    >
      <span className={attachIconClasses} /> Прикрепить файл
    </button>
  </div>
)

const FileInput: React.FC<
  FieldInputProps<FileFieldInterface> & FileFieldInterface
> = ({
  name,
  format,
  label = '',
  maxFileSize,
  allowFileFormat,
  disabled,
  value,
  previewImage = {
    preview: '',
    isShow: false,
  },
}) => {
  const [fileNames, setFileNames] = useState('')
  const [filePreview, setFilePreview] = useState(previewImage.preview)
  const dictionary = useLanguageDictionary()

  const { setFieldValue } = useFormikContext()
  const shadowInputRef = useRef<HTMLInputElement>(null)

  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  const safeSetFilePreview = useCallback((value: string) => {
    setFilePreview((prev) => {
      if (prev.startsWith('blob:')) {
        URL.revokeObjectURL(prev)
      }

      return value
    })
  }, [])

  const resetField = useCallback(() => {
    setFileNames('')
    safeSetFilePreview('')
    setFieldValue(name, '', true)
    if (shadowInputRef.current) {
      shadowInputRef.current.value = ''
    }
  }, [name, safeSetFilePreview, setFieldValue])

  useEffect(() => {
    if (!value) {
      resetField()
    }
  }, [value])

  const setFieldFileValue = useCallback(
    (value: FileList) => {
      setFileNames((prev) => setFileNamesStr(value) || prev)
      safeSetFilePreview(URL.createObjectURL(value[0]))
      setFieldValue(name, value, true)
    },
    [name, safeSetFilePreview, setFieldValue],
  )

  const resetFieldFileValue: React.MouseEventHandler = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation()
      resetField()
    },
    [resetField],
  )

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldFileValue(event.target.files!)
    },
    [setFieldFileValue],
  )

  const dragUploadFile = useCallback(
    (values: FileList | null) => {
      if (values) {
        setFieldFileValue(values)
      }
    },
    [setFieldFileValue],
  )

  const targetClick: React.MouseEventHandler = () => {
    shadowInputRef.current!.click()
  }

  const PreviewBlock: React.FC<{ showCloseIcon?: boolean }> = useCallback(
    ({ showCloseIcon }) => (
      <div className="preview-wrapper">
        {showCloseIcon && (
          <CloseIcon
            className="reset-field"
            disabled={disabled}
            onClick={resetFieldFileValue}
          />
        )}
        <img className="check-preview" src={filePreview} alt="image-preview" />
      </div>
    ),
    [disabled, resetFieldFileValue, filePreview],
  )

  useEffect(() => {
    if (previewImage.preview) {
      fetch(previewImage.preview)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'preview.png')
          const fileList = new DataTransfer()
          fileList.items.add(file)
          setFieldValue(name, fileList.files)
        })
    }
  }, [name, previewImage.preview, setFieldValue])

  return (
    <div>
      <FileDrop {...{ onDrop: dragUploadFile }}>
        <div>
          {format === 'button' ? (
            <div className="upload-button-wrapper">
              <span className="btn-label">{label}</span>
              {previewImage.isShow && !!filePreview && (
                <div className="upload-button-preview">
                  <PreviewBlock />
                </div>
              )}
              <div className={btnWrapperClasses}>
                <UploadButton disabled={disabled} onClick={targetClick} />
                {!!fileNames && (
                  <span className={fileNamesClasses}>{fileNames}</span>
                )}
              </div>
              <AllowFormat
                allowFileFormat={allowFileFormat?.join(', ')}
                maxFileSize={maxFileSize}
              />
            </div>
          ) : (
            <div
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              className={dragAreaClasses}
            >
              <span className="btn-label">{label}</span>
              <div onClick={targetClick} className={dragWrapperClasses}>
                {!filePreview && (
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53.605"
                      height="49.549"
                      viewBox="0 0 53.605 49.549"
                    >
                      <g>
                        <g>
                          <path d="M43.6,12.941a15.528,15.528,0,0,0-28.77-5.159,8.911,8.911,0,0,0-1.632-.154,8.509,8.509,0,0,0-8.5,8.5,9.321,9.321,0,0,0,.176,1.764A11.825,11.825,0,0,0,0,27.425a12.641,12.641,0,0,0,3.208,8.367,11.449,11.449,0,0,0,7.936,3.99h9.568a1.488,1.488,0,1,0,0-2.976H11.277c-4.509-.276-8.3-4.563-8.3-9.392a8.837,8.837,0,0,1,4.376-7.606A1.5,1.5,0,0,0,8,18a5.411,5.411,0,0,1-.33-1.9,5.532,5.532,0,0,1,5.522-5.523,5.413,5.413,0,0,1,1.885.331,1.5,1.5,0,0,0,1.863-.761,12.551,12.551,0,0,1,23.843,4.134,1.488,1.488,0,0,0,1.235,1.323,10.727,10.727,0,0,1-1.015,21.186H32.882a1.488,1.488,0,1,0,0,2.976h8.289a13.193,13.193,0,0,0,8.841-4.365A13.72,13.72,0,0,0,43.6,12.941Z" />
                          <path
                            id="path5314"
                            d="M35.736,28.836a1.482,1.482,0,0,0,0-2.1L27.855,18.85a1.506,1.506,0,0,0-1.047-.441,1.456,1.456,0,0,0-1.047.441l-7.882,7.881a1.494,1.494,0,0,0,1.048,2.546,1.456,1.456,0,0,0,1.047-.441L25.32,23.49V48.06a1.488,1.488,0,1,0,2.976,0V23.49l5.346,5.346A1.47,1.47,0,0,0,35.736,28.836Z"
                          />
                        </g>
                      </g>
                    </svg>{' '}
                    {dictionary.header.uploadFiles}
                  </p>
                )}
                {!!filePreview && <PreviewBlock showCloseIcon />}
              </div>
              <div className={uploadRulesClasses}>
                <AllowFormat
                  allowFileFormat={allowFileFormat?.join(', ')}
                  maxFileSize={maxFileSize}
                />
              </div>
            </div>
          )}
        </div>
      </FileDrop>
      <input
        onChange={onFileInputChange}
        ref={shadowInputRef}
        type="file"
        accept="image/,.jpeg,.png,.jpg"
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default FileInput
