import React from 'react'

import Image from 'next/image'

import TitleImage from '../_images/title.png'
import MenuNavLink from '../MenuNavLink'

const HeaderLogo: React.FC = () => (
  <div className="logo-container">
    <MenuNavLink to="/" scroll isLogoLink>
      <Image
        src={TitleImage.src}
        alt="Выгода в банке"
        width={594}
        height={78}
      />
    </MenuNavLink>
  </div>
)

export default HeaderLogo
