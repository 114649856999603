import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'

import classNames from 'classnames'
import Select, {
  CSSObjectWithLabel,
  MultiValue,
  OptionProps,
  SelectInstance,
  ValueContainerProps,
} from 'react-select'

import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import { ClearIcon } from './icon/ClearIcon'
import { DropDownIcon } from './icon/DropDownIcon'
import {
  controlStaticStyle,
  inputStaticStyle,
  menuListStaticStyle,
  menuStaticStyle,
  multiValueRemoveStaticStyle,
  multiValueStaticStyle,
  optionStaticStyle,
  singleValueStaticStyle,
  valueContainerStaticStyle,
} from './styles'
import { RootSelectInterface, SelectOptionType } from './types'

import s from './RootSelect.module.scss'

const color = {
  gray: '#E4E8EE',
  gray_light: '#E8ECF3',
  gray_dark: '#9DA6BA',
}

const RootSelect: FC<RootSelectInterface> = ({
  stickyBottomSide = false,
  stickyTopSide = false,
  stickyLeftSide = false,
  stickyRightSide = false,
  label,
  placeholder,
  size = 'auto',
  isColor,
  ...props
}) => {
  const { isMobile } = useContext(ViewportContext)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [placeholderShown, setPlaceholderShown] = useState(true)
  const onTouch = (e: string) => {
    if (!isDirty) {
      setIsDirty(true)
    }
    if (e.length > 0) {
      setPlaceholderShown(false)
    } else {
      setPlaceholderShown(true)
    }
  }
  const height = useMemo(
    () =>
      (size === 'large' && 60) ||
      (size === 'default' && (isMobile ? 45 : 50)) ||
      (size === 'default' && (isMobile ? 45 : 50)) ||
      (size === 'middle' && 48) ||
      (size === 'small' && 35) ||
      (size === 'auto' && 50) ||
      '100%',
    [size, isMobile],
  )

  const containerStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      height,
    }),
    [height],
  )

  const controlStyle: any = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...controlStaticStyle,
      backgroundColor: 'white',
      padding: 0,
      borderRadius: 0,
      fontSize: size === 'small' ? 12 : 14,
      color,
      border: 0,
      borderBottom: `1px solid #C4C6C8`,
      height,
    }),
    [
      props.isDisabled,
      props.isMulti,
      isColor,
      size,
      isMobile,
      stickyLeftSide,
      stickyTopSide,
      stickyRightSide,
      stickyBottomSide,
      height,
    ],
  )

  const valueContainerStyle = useCallback(
    (
      baseStyle: CSSObjectWithLabel,
      state: ValueContainerProps<SelectOptionType, true, any>,
    ) => {
      const value = state.selectProps.value as MultiValue<SelectOptionType>
      // const input = state.selectProps.inputValue
      // const isOpen = state.selectProps.menuIsOpen
      const dynamicStyleWithLabel =
        (!!label && value) || !placeholder
          ? {
              paddingTop: 2,
            }
          : {}

      return {
        ...baseStyle,
        ...valueContainerStaticStyle,
        ...dynamicStyleWithLabel,
      }
    },
    [label, placeholder],
  )

  const multiValueStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => {
      const value = state.selectProps.value as MultiValue<SelectOptionType>
      return {
        ...baseStyle,
        ...multiValueStaticStyle,
        display: value.length > 1 ? 'none' : 'flex',
      }
    },
    [],
  )

  const multiValueRemoveStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...multiValueRemoveStaticStyle,
      path: {
        fill: `${color.gray}`,
        ...multiValueRemoveStaticStyle.path,
      },
      ':hover': {
        path: {
          fill: `${color.gray}`,
        },
      },
      ':active': {
        path: {
          fill: `${color.gray_light}`,
        },
      },
    }),
    [],
  )

  const dropdownIndicatorStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => ({
      ...baseStyle,
      transform: `rotate(${state.isFocused ? '180deg' : '0deg'})`,
    }),
    [],
  )

  const clearIndicatorStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      backgroundColor: 'transparent',
      path: {
        fill: color.gray,
      },
      ':hover': {
        path: {
          fill: color.gray,
        },
      },
    }),
    [],
  )

  const singleValueStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...singleValueStaticStyle,
    }),
    [],
  )

  const placeholderStyle = useCallback(
    () => ({
      display: 'none',
    }),
    [],
  )

  const inputStyle = useCallback(
    (baseStyle: CSSObjectWithLabel, state: any) => {
      const isValue = state.getValue().length > 0

      return {
        ...baseStyle,
        ...inputStaticStyle,
        padding: 0,
        margin: 0,
        caretColor: 'black',
        opacity: 1,
        marginBottom: props.isSearchable ? (isValue ? 0 : 8) : 0,
      }
    },
    [props.isSearchable],
  )

  const menuStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      border: `0px solid ${color.gray}`,
      ...menuStaticStyle,
    }),
    [],
  )

  const menuListStyle = useCallback(
    (baseStyle: CSSObjectWithLabel) => ({
      ...baseStyle,
      ...menuListStaticStyle,
      backgroundColor: color.gray,
      '::-webkit-scrollbar-thumb': {
        backgroundColor: color.gray,
        ...menuListStaticStyle['::-webkit-scrollbar-thumb'],
      },
    }),
    [],
  )

  const optionStyle = useCallback(
    (
      baseStyle: CSSObjectWithLabel,
      state: OptionProps<SelectOptionType, true, any>,
    ) => ({
      ...baseStyle,
      ...optionStaticStyle,
      fontSize: 14,
      padding: `13px 15px`,
      color: 'black',
      backgroundColor: 'white',
      ':hover': {
        backgroundColor: '#F0F0F2',
      },
      ':active': {
        backgroundColor: '#C4C6C8',
      },
      '::before': {
        ...optionStaticStyle['::before'],
        content: props.isMulti ? "''" : 'none',
        borderColor: state.isSelected ? '#56BCF4' : '#ECF1F7',
        backgroundColor: state.isSelected ? '#56BCF4' : 'white',
        backgroundImage: state.isSelected
          ? `url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.34133 0.223211C8.64849 0.520475 8.64807 1.00204 8.34041 1.29881L3.69713 5.77768C3.38983 6.07411 2.89213 6.07411 2.58483 5.77768L0.23102 3.50721C-0.0766452 3.21044 -0.0770591 2.72888 0.230095 2.43162C0.53725 2.13435 1.03566 2.13395 1.34332 2.43072L3.14098 4.16473L7.2281 0.222317C7.53577 -0.0744542 8.03418 -0.0740544 8.34133 0.223211Z' fill='white'/%3E%3C/svg%3E");`
          : 'none',
      },
    }),
    [isColor, props.isMulti],
  )

  const ref = useRef<SelectInstance>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isHaveValue, setIsHaveValue] = useState(
    ref.current?.getValue().length! > 0,
  )
  const isCursorActive = props.isSearchable && ref.current?.state.isFocused
  const isSearchInputHaveValue = ref.current?.inputRef?.value

  useEffect(() => {
    setIsHaveValue(
      Array.isArray(props.value) ? props.value.length > 0 : !!props.value,
    )
  }, [props.value])

  const placeholderClasses = classNames(
    s['placeholder'],
    !label &&
      (isMenuOpen || isHaveValue || isCursorActive ? s['top'] : s['center']),
    label && s['center'],
    label &&
      (isHaveValue || !placeholderShown || isSearchInputHaveValue) &&
      s['hide'],
  )

  return (
    <div className={s['wrapper']}>
      <div className={placeholderClasses}>{placeholder}</div>
      <Select
        // @ts-ignore
        ref={ref}
        classNamePrefix="promo"
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        loadingMessage={() => 'Поиск..'}
        // https://github.com/JedWatson/react-select/issues/4455
        blurInputOnSelect={false}
        noOptionsMessage={() => 'Не найдено'}
        isClearable={props.isMulti}
        instanceId={props.name}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: DropDownIcon,
          ClearIndicator: ClearIcon,
        }}
        styles={{
          container: containerStyle,
          control: controlStyle,
          valueContainer: valueContainerStyle,
          multiValue: multiValueStyle,
          multiValueRemove: multiValueRemoveStyle,
          dropdownIndicator: dropdownIndicatorStyle,
          clearIndicator: clearIndicatorStyle,
          singleValue: singleValueStyle,
          input: inputStyle,
          menu: menuStyle,
          menuList: menuListStyle,
          option: optionStyle,
          placeholder: placeholderStyle,
        }}
        onInputChange={onTouch}
        onMenuOpen={() => {
          setIsMenuOpen(true)
        }}
        onMenuClose={() => {
          setIsMenuOpen(false)
        }}
        openMenuOnFocus
        {...props}
      />
    </div>
  )
}

export default RootSelect
