const messages = {
  passwordSuccessChanged: 'Успешно',
  successRegistration:
    'Для завершения регистрации перейдите по ссылке из письма, отправленного на вашу почту',
  restorePassword:
    'Для восстановления пароля перейдите по ссылке из письма, отправленного на вашу почту',
  sendFeedBack: 'Сообщение отправлено',
  successSendData: 'Данные успешно отправлены',
  needVerifiedEmail: 'Подтвердите почту',
  promocodeIsUpload: 'Промокод загружен',
  reviewIsUpload: 'Ваш отзыв отправлен на модерацию!',
  successReview: 'О результате его проверки вы получите сообщение на почту',
  checkIsUpload: 'Чек загружен и\u00A0отправлен на модерацию',
  successCheck:
    'По результатам проверки вы\u00A0получите уведомление на\u00A0указанный при регистрации E‑mail',
  thanks: 'Спасибо!',
}

export default messages
