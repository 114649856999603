import React, { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { UserType } from '$store/slices/config/types/user/userType'
import { showPopup } from '$store/slices/popup'
import { confirmUserPhone } from '$store/slices/user'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useTimer from '$hooks/useTimer/useTimer'

import Button from '$components/ui/buttons/RootButton/Button'
import ClipSpinner from '$components/ui/spinners/ClipSpinner/ClipSpinner'

import { goToAccountRoom } from '$utils/navigation/account'
import numWord from '$utils/numWord/numWord'

const AlternativeForm: React.FC = () => {
  const router = useRouter()
  const dictionary = useLanguageDictionary()
  const [number, setNumber] = useState<string | null>(null)
  const [qr, setQr] = useState<string | null>(null)

  const dispatch = useDispatch()

  const phone = useAppSelector(
    (store) => store.popup.popupConfig.popupData?.phone,
  )

  const [remaining, reset] = useTimer(120)

  const user = useAppSelector((store) => store.user) as UserType

  useEffect(() => {
    pbApi
      .sendConfirmPhoneWithAlternate(phone)
      .then(({ data }) => {
        setNumber(data.confirmation_number)
        setQr(data.qr_code)
      })
      .catch(({ response: { data = {} } = {} }) => {
        const err = data.errors?.[0] ?? 'Непредвиденная ошибка'
        dispatch(
          showPopup({
            title: err,
            type: 'error',
          }),
        )
      })
  }, [])

  useEffect(() => {
    const checkVerified = setInterval(() => {
      pbApi.confirmPhoneAlter(phone).then(({ data }) => {
        if (data.verified) {
          clearInterval(checkVerified)
          dispatch(confirmUserPhone())
          goToAccountRoom(router)
          dispatch(
            showPopup({
              title: dictionary.messages.thanks,
              message: dictionary.messages.successRegistration,
              type: 'success',
            }),
          )
        }
      })
    }, 3500)
    return () => {
      clearInterval(checkVerified)
    }
  }, [user])

  return (
    <>
      {remaining > 0 ? (
        <>
          <h2 className="popup__title popup__title_phone">
            {dictionary.header.confirmPhone}
          </h2>
          <p
            style={{ maxWidth: 500, margin: '0 auto' }}
            className="popup__text"
          >
            Для подтверждения номера совершите{' '}
            <span style={{ color: 'red', fontWeight: 700 }}>БЕСПЛАТНЫЙ</span>{' '}
            вызов на&nbsp;номер в течение <br />
            {`${remaining} ${numWord(remaining, [
              'секунды',
              'секунды',
              'секунд',
            ])}`}
            :
          </p>
          <div
            style={{
              margin: '10px 0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {number ? (
              <>
                <a
                  style={{
                    fontSize: 24,
                    color: 'black',
                    textDecoration: 'none',
                    margin: '10px auto',
                    marginBottom: '20px',
                  }}
                  href={`tel:+${number}`}
                >
                  +{number}
                </a>
                {qr && (
                  <>
                    <span
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      или отсканируйте QR
                    </span>
                    <img
                      style={{ margin: '20px auto' }}
                      width={230}
                      height={230}
                      src={qr}
                      alt=""
                    />
                  </>
                )}
              </>
            ) : (
              <ClipSpinner size={70} />
            )}
          </div>
        </>
      ) : (
        <>
          <h2 className="popup__title popup__title_lg">
            {dictionary.header.confirmPhone}
          </h2>
          <Button
            onClick={() => {
              pbApi
                .sendConfirmPhoneWithAlternate(phone)
                .then(({ data }) => {
                  setNumber(data.confirmation_number)
                  setQr(data.qr_code)
                })
                .catch(({ response: { data = {} } = {} }) => {
                  const err = data.errors?.[0] ?? 'Непредвиденная ошибка'
                  dispatch(
                    showPopup({
                      title: err,
                      type: 'error',
                    }),
                  )
                })
              reset()
            }}
          >
            Попробовать еще
          </Button>
        </>
      )}
    </>
  )
}

const ConfirmPhonePopup = () => (
  <div>
    <AlternativeForm />
  </div>
)

export default ConfirmPhonePopup
