import React, { useCallback, useContext, useMemo } from 'react'

import classNames from 'classnames'
import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import popupsID from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'
import viewTemplate from '$constants/config/viewTemplate/viewTemplate'

import { useAppSelector } from '$store/hooks'
import { logOut } from '$store/slices/user/thunks/user'
import { AppDispatch, RootState } from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'

import { HeaderContext } from '$services/HeaderMenuService/HeaderMenuService'

import Button from '$components/ui/buttons/RootButton/Button'

import { popupLink } from '$utils/link'
import numWord from '$utils/numWord/numWord'
import { combineUrl } from '$utils/routing'

import OptionsLink from './OptionLink/OptionLink'

const NavBtn: React.FC<{
  onClick: () => void
  placeholder: string
  outline?: boolean
  dataTest?: string
}> = ({ onClick, placeholder, outline, dataTest }) => (
  <Button dataTest={dataTest} outline={outline} onClick={onClick}>
    {placeholder}
  </Button>
)

const HeaderMenu: React.FC<Props> = ({ configState, isUserUpload, logout }) => {
  const TemplateConfig = useMemo(
    () => ({
      registration: {
        [viewTemplate.MODAL]: popupLink(popupsID.sign_up),
        [viewTemplate.PAGE]: routePath.reg,
      },
      authorization: {
        [viewTemplate.MODAL]: popupLink(popupsID.sign_in),
        [viewTemplate.PAGE]: routePath.auth,
      },
    }),
    [],
  )

  const balance = useAppSelector(
    (state: RootState) => state.config.motivational_program?.balance,
  )

  const dictionary = useLanguageDictionary()

  const { setOpenMenuState } = useContext(HeaderContext)
  const router = useRouter()

  const REGISTRATION = configState.auth?.viewTemplate
  const onPersonalPage = useMemo(
    (): boolean => router.pathname.includes('/business-challenge'),
    [router.pathname],
  )

  const redirectHandler = useCallback(
    (to: string) => () => {
      router.push(combineUrl(router.asPath, to), undefined, {
        shallow: true,
      })

      setOpenMenuState(false)
    },
    [router, setOpenMenuState],
  )

  const logoutHandler = useCallback(async () => {
    await router.push('/')
    await logout()
    setOpenMenuState(false)
  }, [logout, router, setOpenMenuState])

  const promoStatus = configState.promo.status

  const postPromoStatusReg = usePromoStatus(true, {
    title: 'Регистрация закрыта',
  })

  const postPromo = useCallback(() => {
    postPromoStatusReg()
  }, [postPromoStatusReg])

  const motivationalProgram = useAppSelector(
    ({ config: { motivational_program } }) => motivational_program!,
  )

  if (!REGISTRATION || REGISTRATION === viewTemplate.HIDDEN) {
    return null
  }

  const isParticipant = motivationalProgram.role === 'participant'

  return (
    <div
      className={classNames(
        'header-menu__item sign-in',
        isUserUpload && 'auth',
      )}
    >
      {isUserUpload && (
        <div className="balance">
          {isParticipant && (
            <div>
              Баланс: {balance ?? 0}{' '}
              {numWord(balance ?? 0, ['балл', 'балла', 'баллов'])}
            </div>
          )}
          <OptionsLink />
        </div>
      )}
      <div className="nav-btn__wrapper">
        {isUserUpload ? (
          onPersonalPage ? (
            isParticipant ? (
              <NavBtn
                onClick={redirectHandler('/account/data')}
                placeholder={dictionary.button.account}
                outline
              />
            ) : null
          ) : (
            <NavBtn
              onClick={redirectHandler('/account/business-challenge')}
              placeholder={isParticipant ? 'Бизнес задачи' : 'Торговые точки'}
              outline
            />
          )
        ) : promoStatus === 'postpromo' ? (
          <NavBtn
            onClick={postPromo}
            placeholder={dictionary.button.signUp}
            dataTest="button-registration"
            outline
          />
        ) : (
          <NavBtn
            onClick={redirectHandler(TemplateConfig.registration[REGISTRATION])}
            placeholder={dictionary.button.signUp}
            dataTest="button-registration"
            outline
          />
        )}
      </div>
      <div className="nav-btn__wrapper">
        <NavBtn
          onClick={
            isUserUpload
              ? logoutHandler
              : redirectHandler(TemplateConfig.authorization[REGISTRATION])
          }
          placeholder={
            isUserUpload ? dictionary.button.logOut : dictionary.button.signIn
          }
          dataTest={isUserUpload ? 'button-logout' : 'button-sign-in'}
          outline
        />
      </div>
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    configState: state.config,
    isUserUpload: !!state.user,
    check: state.config.check,
  }),
  (dispatch: AppDispatch) => ({
    logout: () => dispatch(logOut()),
  }),
)

export default connector(HeaderMenu)
