export const DropDownIcon: React.FC<any> = ({ innerProps, selectProps }) => (
  <div
    {...innerProps}
    style={{
      transform: `rotate(${selectProps.menuIsOpen ? '180deg' : '0deg'})`,
      transition: 'all .3s',
      cursor: 'pointer',
    }}
  >
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        stroke="#C4C6C8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
)
