import { FC, PropsWithChildren, ReactElement } from 'react'

import classNames from 'classnames'

import Footer from '$components/layoutParts/Footer/Footer'
import Header from '$components/layoutParts/Header/Header'

import classes from './DefaultLayout.module.scss'

const DefaultLayout: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className={classes['main-root']}>
    <main
      id="main-root"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <div
        className={classNames(classes['contentContainer'])}
        style={{ flexGrow: 1 }}
      >
        {children}
      </div>
      <Footer />
    </main>
  </div>
)

export const getDefaultLayout = (page: ReactElement) => (
  <DefaultLayout>{page}</DefaultLayout>
)

export default DefaultLayout
