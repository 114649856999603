import React, { PropsWithChildren } from 'react'

import classNames from 'classnames'

import classes from './PageWrapper.module.scss'

const PageWrapper: React.FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => (
  <div className={classNames(classes['pageWrapper'], className)}>
    {children}
  </div>
)

export default PageWrapper
