import React, { useMemo } from 'react'

import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'
import AuthFieldNames from '$constants/common/forms/auth/fields/AuthFieldsName'

import { hidePopup, showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import FormikWrapper from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import initializeValue from '$form/formik/utils/InitializeFormikValues'

import onlyStringParam from '$utils/onlyStringParam'

const RestorePasswordForm: React.FC<Props> = ({
  showPopupAction,
  hidePopupAction,
}) => {
  const router = useRouter()
  const dictionary = useLanguageDictionary()
  const initialValues = useMemo(
    () => initializeValue([AuthFieldNames.password, 'password_confirmation'])(),
    [],
  )

  const { isRequired, minLength } = useValidators()

  const changePassword = useFormSubmitHandler<typeof initialValues>(
    [
      (values, { setSubmitting, setFieldError }) => {
        if (values.password !== values.password_confirmation) {
          setFieldError(
            authFieldCollection.password.name,
            dictionary.errors.passwordsNotMatch,
          )
          setFieldError(
            'password_confirmation',
            dictionary.errors.passwordsNotMatch,
          )
          setSubmitting(false)
          return undefined
        }
        return values
      },
    ],
    (values) => {
      // TODO: check for token on `new-password` page
      const token = onlyStringParam(router.query['token']) ?? ''
      return pbApi
        .newPassword({
          password: values.password!,
          password_confirmation: values.password_confirmation!,
          token,
        })
        .then(() => {
          showPopupAction({
            type: 'success',
            title: dictionary.messages.passwordSuccessChanged,
            message: 'Пароль изменен',
          })
          setTimeout(() => {
            hidePopupAction()
            router.replace('/')
          }, 3000)
        })
    },
  )

  return (
    <FormikWrapper
      name="newpass"
      onSubmit={changePassword}
      initialValues={initialValues}
      submitButtonName={dictionary.button.change}
    >
      <TextInputField
        name={authFieldCollection.password.name}
        placeholder={dictionary.fields.new_password}
        type={authFieldCollection.password.type}
        validate={validatorCombiner([isRequired, minLength(8)])}
      />
      <TextInputField
        name="password_confirmation"
        placeholder={dictionary.fields.repeat_password}
        type={authFieldCollection.password.type}
        validate={validatorCombiner([isRequired, minLength(8)])}
      />
    </FormikWrapper>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  () => ({}),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
    hidePopupAction: () => {
      dispatch(hidePopup())
    },
  }),
)

export default connector(RestorePasswordForm)
