import React from 'react'

import { useAppSelector } from '$store/hooks'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import SignUpFormBlock from '$components/blocks/AuthFormBlock/AuthFormBlock'

const SignUpPopup = () => {
  const dictionary = useLanguageDictionary()

  const { isConfirm } = useAppSelector((store) => store.config.inviteCode)

  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {isConfirm ? dictionary.header.signUp : 'введите пригласительный код'}
      </h2>
      <SignUpFormBlock />
    </div>
  )
}

export default SignUpPopup
