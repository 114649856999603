import React from 'react'

import Image from 'next/image'
import { connect, ConnectedProps, useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { setConfig } from '$store/slices/config'
import { RootState } from '$store/store'

import useTimer from '$hooks/useTimer/useTimer'

import { Rubik } from '$components/ui/text/Rubik/indext'

import pluralForm from '$utils/dayCaseParser/dayCaseParser'

import MetaTags from '$/other/meta-tags/MetaTags'

import Logo from './logo.svg?component'
import Title from './title.png'

import classes from './PlugTimer.module.scss'

const PlugTimer: React.FC<Props> = ({ promoStart }) => {
  const description = useAppSelector((store) => store.config.promo.description)
  const name = useAppSelector((store) => store.config.promo.name_readable)

  const dateStart = new Date(promoStart)
  const dateNow = new Date()
  const datePlug = dateStart.getTime() - dateNow.getTime()
  const [time] = useTimer(Math.floor(datePlug / 1000))

  const dispatch = useDispatch()

  if (time === 0) {
    setTimeout(() => {
      pbApi.getFullConfig().then(({ data: { data } }) => {
        dispatch(setConfig(data))
      })
    }, 1000)
  }

  const day = Math.floor(time / (60 * 60 * 24))
  const hour = Math.floor((time - day * (60 * 60 * 24)) / (60 * 60))
  const minute = Math.floor(
    (time - day * (60 * 60 * 24) - hour * (60 * 60)) / 60,
  )
  const seconds = `0${Math.floor(
    time - day * (60 * 60 * 24) - hour * (60 * 60) - minute * 60,
  )}`.slice(-2)

  const dayLable = pluralForm(day, 'день', 'дня', 'дней')
  const hourLable = pluralForm(hour, 'чаc', 'часа', 'часов')
  const minuteLable = pluralForm(minute, 'минута', 'минуты', 'минут')
  const secondLable = pluralForm(time, 'секунда', 'секунды', 'секунд')

  const minuteRow = `0${minute}`.slice(-2)

  return (
    <>
      <MetaTags rawTitle title={name} description={description} />
      <div className={classes['page']}>
        <div className={classes['plug']}>
          <div className={classes['logo']}>
            <Logo />
          </div>
          <Image
            className={classes['title']}
            src={Title.src}
            width={700}
            height={210}
            alt="выгода в банке"
          />
          <h2 className={classes['before-start']}>
            <Rubik>До старта</Rubik>
          </h2>
          <div className={classes['clock']}>
            <div className={classes['numb']}>
              <span className="digit">{day}</span>
              <span className={classes['word']}>{dayLable}</span>
            </div>
            <div className={classes['numb']}>
              <span className="digit">{hour}</span>
              <span className={classes['word']}>{hourLable}</span>
            </div>
            <div className={classes['numb']}>
              <span className="digit">{minuteRow}</span>
              <span className={classes['word']}>{minuteLable}</span>
            </div>
            <div className={classes['numb']}>
              <span className="digit">{seconds}</span>
              <span className={classes['word']}>{secondLable}</span>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect((state: RootState) => ({
  promoStart: state.config.promo.period.promo_start,
}))

export default connector(PlugTimer)
