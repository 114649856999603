import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'classnames'

import classes from './CloseIcon.module.scss'

const CloseIcon: React.FC<ButtonHTMLAttributes<unknown>> = ({
  onClick,
  disabled,
  className,
  ...props
}) => (
  <div
    {...props}
    onClick={onClick}
    className={classNames(
      classes['icon'],
      disabled && classes['icon_disabled'],
      className,
    )}
  >
    <svg
      width="19"
      height="19"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L9 9M9 9L17 17M9 9L17 1M9 9L1 17"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </div>
)

export default CloseIcon
