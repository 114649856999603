import React, { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import CloseIcon from '$components/icons/close/CloseIcon'

import { RootInputInterface } from './types'

import s from './RootInput.module.scss'

const placeholderClass = classNames(
  s['input-wrapper__placeholder'],
  s['input-wrapper__placeholder_label'],
)

const RootInput: React.FC<RootInputInterface> = ({
  mask,
  label = '',
  placeholder = '',
  type = 'text',
  name,
  disabled,
  value,
  onChange,
  ...props
}) => {
  const [inputType, setInputType] = useState(type)
  const [stateValue, setValue] = useState<typeof value>(value ?? '')
  const isFullLabel = (placeholder && label) || !placeholder
  const fieldValue: string = stateValue as string
  const inputClass = useMemo(
    (): string =>
      classNames(s['input-wrapper__input'], {
        [s['input-wrapper__input_active']]: !!fieldValue,
        [s['basic-placeholder']]: isFullLabel,
      }),
    [isFullLabel, fieldValue],
  )

  const inputWrapperClass = classNames(s['input-wrapper__field'], {
    [s['disabled']]: disabled,
  })

  const optionBarClasses = useMemo(() => classNames(s['option-bar']), [])

  const changeNumberFieldHandler = (newValue: NumberFormatValues) => {
    setValue(newValue.value)
    onChange(newValue.value)
  }

  useEffect(() => {
    setValue(value)
  }, [value])

  const handleOpenPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  const isNumeric = useMemo((): boolean => !!mask, [mask])
  return (
    <div className={s['input-wrapper']}>
      <div className={inputWrapperClass}>
        {isNumeric ? (
          // @ts-ignore
          <NumberFormat
            className={inputClass}
            allowEmptyFormatting
            placeholder={isFullLabel ? placeholder : ''}
            name={name}
            format={mask}
            value={fieldValue}
            mask="_"
            disabled={disabled}
            onValueChange={changeNumberFieldHandler}
            data-test={props['data-test']}
            {...props}
          />
        ) : (
          <input
            className={inputClass}
            placeholder={isFullLabel ? placeholder : ''}
            value={fieldValue}
            disabled={disabled}
            name={name}
            type={inputType}
            data-test={props['data-test']}
            onChange={(e) => {
              const { value: eventValue } = e.target

              const val =
                eventValue === ''
                  ? ''
                  : type === 'number'
                  ? +eventValue
                  : eventValue

              setValue(val)
              onChange(val)
            }}
            {...props}
          />
        )}
        <div className={optionBarClasses}>
          {type === 'password' && (
            <div onClick={handleOpenPassword} className={s['password-icon']}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
              >
                <g opacity="0.8">
                  <path
                    d="M13.9742 10.238C15.6752 8.72 16.6152 7 16.6152 7C16.6152 7 13.6152 1.5 8.6152 1.5C7.65479 1.50331 6.70525 1.70342 5.8252 2.088L6.5952 2.859C7.24263 2.62315 7.92614 2.50168 8.6152 2.5C10.7352 2.5 12.4942 3.668 13.7832 4.957C14.4035 5.58069 14.9597 6.26513 15.4432 7C15.3852 7.087 15.3212 7.183 15.2482 7.288C14.9132 7.768 14.4182 8.408 13.7832 9.043C13.6182 9.208 13.4462 9.371 13.2662 9.529L13.9742 10.238Z"
                    fill="#212529"
                  />
                  <path
                    d="M11.9126 8.17599C12.1357 7.55183 12.1771 6.87714 12.0318 6.23041C11.8864 5.58368 11.5605 4.99151 11.0918 4.52281C10.6231 4.0541 10.0309 3.72813 9.38417 3.58282C8.73744 3.43751 8.06275 3.47883 7.43859 3.70199L8.26159 4.52499C8.6459 4.46998 9.03774 4.50523 9.40606 4.62795C9.77438 4.75067 10.1091 4.95748 10.3836 5.232C10.6581 5.50652 10.8649 5.8412 10.9876 6.20952C11.1103 6.57784 11.1456 6.96968 11.0906 7.35399L11.9126 8.17599ZM8.96959 9.47499L9.79159 10.297C9.16743 10.5201 8.49274 10.5615 7.84601 10.4162C7.19928 10.2708 6.60711 9.94488 6.13841 9.47617C5.6697 9.00746 5.34373 8.41529 5.19842 7.76857C5.05311 7.12184 5.09443 6.44715 5.31759 5.82299L6.14059 6.64599C6.08558 7.0303 6.12083 7.42214 6.24355 7.79046C6.36627 8.15878 6.57308 8.49346 6.8476 8.76798C7.12212 9.0425 7.4568 9.24931 7.82512 9.37203C8.19344 9.49474 8.58528 9.53 8.96959 9.47499Z"
                    fill="#212529"
                  />
                  <path
                    d="M3.96523 4.47C3.78523 4.63 3.61223 4.792 3.44723 4.957C2.82688 5.58069 2.27075 6.26512 1.78723 7L1.98223 7.288C2.31723 7.768 2.81223 8.408 3.44723 9.043C4.73623 10.332 6.49623 11.5 8.61523 11.5C9.33123 11.5 10.0052 11.367 10.6352 11.14L11.4052 11.912C10.5252 12.2965 9.57564 12.4967 8.61523 12.5C3.61523 12.5 0.615234 7 0.615234 7C0.615234 7 1.55423 5.279 3.25623 3.762L3.96423 4.471L3.96523 4.47ZM14.2612 13.354L2.26123 1.354L2.96923 0.645996L14.9692 12.646L14.2612 13.354Z"
                    fill="#212529"
                  />
                </g>
              </svg>
            </div>
          )}

          <div>
            <button
              type="button"
              className="reset-field"
              onClick={() => {
                if (!disabled) {
                  setValue('')
                  onChange('')
                }
              }}
            >
              <CloseIcon disabled={disabled} />
            </button>
          </div>
        </div>
        {!isFullLabel && (
          <span className={placeholderClass}>{placeholder}</span>
        )}
      </div>
    </div>
  )
}

export default RootInput
