const fields = {
  email: 'Email',
  first_name: 'Имя',
  last_name: 'Фамилия',
  middle_name: 'Отчество',
  phone: 'Номер телефона',
  region: 'Регион',
  city: 'Город',
  password: 'Пароль',
  repeat_password: 'Повторите пароль',
  birth_date: 'Дата рождения',
  passport_series_number: 'Серия номер паспорта',
  passport_issue_date: 'Дата выдачи паспорта',
  passport_issued_by: 'Выдан кем',
  passport_department_code: 'Код подразделения',
  post_index: 'Почтовый индекс',
  street: 'Улица прописки',
  house: 'Дом прописки',
  apartment: 'Квартира',
  inn: 'ИНН',
  card_number: 'Номер карты',
  passport_copy_photo_file: 'Фото паспорта',
  passport_copy_address_file: 'Фото прописки',
  passport_copy_inn_file: 'Фото ИНН',
  subject: 'Тема сообщения',
  FD: 'ФД',
  FP: 'ФП',
  FN: 'ФН',
  dateTime: 'Дата и время',
  sum: 'Сумма',
  promo_code: 'Промокод',
  changePassword: 'Новый пароль',
  new_password: 'Новый пароль',
  message: 'Текст сообщения',
  block: 'Корпус',
  reviewBy: 'Отзыв для',
  date: 'Дата',
  time: 'Время',
  inviteCode: 'Пригласительный код',
  referral_code: 'Реферальный код',
}

export default fields
