import { useEffect } from 'react'

const useScroll = (activeState: boolean) => {
  useEffect(() => {
    const bodyFixed = () => {
      document.body.style.position = 'fixed'
      document.body.style.top = `0`
    }

    const bodyStatic = () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.documentElement.style.scrollBehavior = 'auto'
      // eslint-disable-next-line radix
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      document.documentElement.style.scrollBehavior = 'smooth'
    }

    const handleScroll = () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`,
      )
    }

    if (activeState) {
      bodyFixed()
    } else {
      bodyStatic()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [activeState])
}

export default useScroll
