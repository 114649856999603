import React, { useMemo, useRef } from 'react'

import classNames from 'classnames'
import { useField } from 'formik'

import Error from '$form/fields/other/errors/Error'
import Label from '$form/fields/other/labels/Label'

import { SimpleFieldInterface } from '../../../types/FormikFieldType'

import s from './FieldWrapperStyle.module.scss'

interface FieldPropsConstructor extends SimpleFieldInterface {
  component: React.FC<any>
}

const FieldWrapperConstructor = ({
  component,
  disableError,
  ...props
}: FieldPropsConstructor) => {
  const { label = '' } = props
  const { validate, ...fieldProps } = props
  const [field, { touched, error }] = useField<any>(props)

  const isError = useMemo((): boolean => touched && !!error, [touched, error])
  const ref = useRef<HTMLLabelElement>(null)

  const FieldComponent = component

  return (
    <label
      ref={ref}
      className={classNames(s['field-wrapper'], isError && 'error')}
    >
      {!!label && <Label label={label} />}
      <FieldComponent {...field} {...fieldProps} />
      {!disableError && isError && <Error error={error!} />}
    </label>
  )
}

export default FieldWrapperConstructor
