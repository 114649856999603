import { useLayoutEffect, useRef, useState } from 'react'

import { useFormikContext } from 'formik'
import CanvasDraw from 'react-canvas-draw'

import Preloader from '$components/layoutParts/Preloader/Preloader'
import Button from '$components/ui/buttons/RootButton/Button'
import DrawCanvas from '$components/ui/DrawCanvas'

import base64ToFile from '$utils/file/base64ToFile'

import { FormikFieldInterface } from '../../type/FormikFieldType'

import s from './s.module.scss'

const DrawCanvasField: React.FC<FormikFieldInterface> = ({
  name,
  validate = () => {},
}) => {
  // const [size] = useState<string | number>(272)
  const [value, setValue] = useState<File | null>(null)
  const ref = useRef<CanvasDraw>(null)
  const {
    setFormikState,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    submitForm,
  } = useFormikContext()

  const errorsFields = Object.keys(errors)
  const touchedFields = Object.keys(touched)
  const isNotValid =
    errorsFields.filter((item) => touchedFields.includes(item)).length > 0

  const disabled = isNotValid || isSubmitting

  const errorsAny = errors as any

  useLayoutEffect(() => {
    const errorText = validate(value)
    const errorField = errorText ? { [name]: errorText } : undefined
    setFormikState((state) => ({
      ...state,
      errors: {
        ...state.errors,
        ...errorField,
      },
    }))
  }, [isSubmitting])

  return (
    <div>
      <div>
        <DrawCanvas
          style={{
            margin: '0 auto',
            border: '1px solid lightgray',
            borderRadius: 8,
          }}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onChange={(e) => {
            const { canvas } = ref.current as any
            const file = base64ToFile(
              canvas.toDataURL('image/png'),
              'image.png',
            )
            setValue(file)
            setFieldValue(name, file)
          }}
          brushRadius={2}
          ref={ref}
          hideGrid
          canvasWidth={290}
          canvasHeight={295}
        />
      </div>
      <div style={{ position: 'relative' }}>
        <div className="error-field" style={{ top: 3 }}>
          {isNotValid && errorsAny[name]}
        </div>
      </div>

      <div
        style={{ marginTop: 30, marginBottom: 15, display: 'flex', gap: 15 }}
      >
        <Button
          onClick={() => {
            const { ctx } = ref.current as any
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            setValue(null)
            setFormikState((state) => ({
              ...state,
              errors: {
                ...state.errors,
                [name]: validate(null),
              },
            }))
          }}
          type="button"
          buttonClassName={s['button']}
          outline
          size="middle"
        >
          Очистить{' '}
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            setFormikState((state) => ({
              ...state,
              touched: {
                ...state.touched,
                [name]: true,
              },
            }))
            submitForm()
          }}
          type="submit"
          size="middle"
        >
          {isSubmitting ? <Preloader /> : 'Подписать'}
        </Button>
      </div>
    </div>
  )
}

export default DrawCanvasField
