import { useCallback } from 'react'

import { useAppStore } from '$store/hooks'
import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'

const usePromoStatus = (
  openPopup: boolean = true,
  popupConfig: PopupConfig = {
    title: 'Приём окончен',
  },
) => {
  const store = useAppStore()

  const PromoStatusHandler = useCallback(() => {
    const { status } = store.getState().config.promo

    if (status === 'postpromo') {
      if (openPopup) {
        store.dispatch(
          showPopup({
            title: popupConfig.title,
            message: `Период регистрации участников в программе «Выгода в банке» завершён 31.05.2024 года`,
          }),
        )
      }

      return true
    }
    return false
  }, [store, openPopup])

  return PromoStatusHandler
}

export default usePromoStatus
