import React, { useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
// fuck react-date-picker
import { FieldInputProps, useFormikContext } from 'formik'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
// double fuck react-time-picker
import TimePicker, {
  TimePickerValue,
} from 'react-time-picker/dist/entry.nostyle'

import Calendar from '$components/icons/calendar/Calendar'
import CloseIcon from '$components/icons/close/CloseIcon'

import { DateTimeFormikFieldInterface } from '$form/fields/formikField/type/FormikFieldType'
import Label from '$form/fields/other/labels/Label'

const ParseDate = (date: Date) => {
  if (!date) {
    return ''
  }
  const day = `0${date.getDate()}`.slice(-2)
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const year = date.getFullYear()
  return `${year}/${month}/${day}`
}

const dateWrapperClasses = classNames('date-wrapper')
const dateTimeWrapperClasses = classNames('date-time-wrapper')
const dateTimeClasses = classNames('datetime')
const pickerClasses = classNames('picker')

type Props = FieldInputProps<DateTimeFormikFieldInterface> &
  DateTimeFormikFieldInterface

const DateTimePickerField: React.FC<Props> = ({
  name,
  dateFormat = 'dd.MM.yyyy',
  timeFormat = 'HH:mm',
  dateLabel = '',
  timeLabel = '',
  format,
  disabled,
}) => {
  const [date, setDate] = useState<Date | null>(null)
  const [isCalendarOpen, setCalendarState] = useState<boolean>(false)
  const [time, setTime] = useState<TimePickerValue>('00:00')
  const { setFieldValue, setFieldTouched, initialValues } = useFormikContext()

  useEffect(() => {
    const initValue: any = initialValues
    if (initValue[name] && format === 'date') {
      const [year, month, day] = initValue[name]?.split('/') ?? ['', '', '']
      setDate(new Date(+year, +month - 1, +day))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // обнуляем инпут времени если выбрано значение равное или больше 24
  useEffect(() => {
    const yearInput = document.querySelectorAll(
      '.react-date-picker__inputGroup__year',
    ) as NodeListOf<HTMLInputElement>
    yearInput.forEach((item) => {
      item.addEventListener('input', () => {
        if (item.value.length > 4) {
          item.value = item.value.slice(0, 4)
        }
      })
    })

    const monthInput = document.querySelectorAll(
      '.react-date-picker__inputGroup__month',
    ) as NodeListOf<HTMLInputElement>
    monthInput.forEach((item) => {
      item.addEventListener('input', () => {
        // const dayInput = item.parentNode?.querySelector('.react-date-picker__inputGroup__day') as HTMLInputElement
        if (+item.value > 12) {
          item.value = '1'
        }
      })
      item.addEventListener('blur', () => {
        if (+item.value === 0) {
          item.value = '01'
        }
      })
    })

    const dayInput = document.querySelectorAll(
      '.react-date-picker__inputGroup__day',
    ) as NodeListOf<HTMLInputElement>
    dayInput.forEach((item) => {
      item.addEventListener('focus', () => {
        if (+item.value > +item.max) {
          item.value = item.max
        }
      })
      item.addEventListener('input', () => {
        if (+item.value > +item.max) {
          item.value = '1'
        }
      })
    })

    const timeInput = document.querySelectorAll(
      '.react-time-picker__inputGroup__hour',
    ) as NodeListOf<HTMLInputElement>
    timeInput.forEach((item) => {
      item.addEventListener('input', () => {
        if (+item.value >= 24) {
          item.value = '0'
        }
      })
    })

    const MinuteInput = document.querySelectorAll(
      '.react-time-picker__inputGroup__minute',
    ) as NodeListOf<HTMLInputElement>
    MinuteInput.forEach((item) => {
      item.addEventListener('input', () => {
        if (+item.value >= 60) {
          item.value = '0'
        }
      })
    })
  }, [])

  const timeRef = useRef<HTMLDivElement>(null)
  const dateRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (date) {
      const fieldValue =
        format === 'datetime'
          ? `${ParseDate(date)} ${time}`
          : `${ParseDate(date)}`
      setFieldValue(name, date ? fieldValue : '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const changeData = useCallback(
    (date: Date) => {
      setFieldTouched(name)
      const fieldValue =
        format === 'datetime'
          ? `${ParseDate(date)} ${time}`
          : `${ParseDate(date)}`
      setFieldValue(name, date ? fieldValue : '')
      setDate(date)
    },
    [format, name, setFieldTouched, setFieldValue, time],
  )

  const changeTime = useCallback(
    (time: TimePickerValue) => {
      setFieldTouched(name)
      const [hour, minute] = `${time}`.split(':')
      const parseTime = `${+hour < 24 ? hour : '00'}:${minute}`
      const fieldValue =
        format === 'datetime'
          ? `${ParseDate(date ?? new Date())} ${parseTime}`
          : `${parseTime}`
      setTime(time)
      setFieldValue(name, time ? fieldValue : '')
    },
    [date, format, name, setFieldTouched, setFieldValue],
  )

  const DataField: React.FC<{
    date: Date | null
  }> = useCallback(
    ({ date }): JSX.Element => (
      <div ref={dateRef} className={dateWrapperClasses}>
        <div className={pickerClasses}>
          <Label className="input-wrapper__label" label={dateLabel} />
          <DatePicker
            onCalendarClose={() => {
              setCalendarState(!isCalendarOpen)
            }}
            disabled={disabled}
            openCalendarOnFocus={false}
            clearIcon={<CloseIcon disabled={disabled} onClick={() => {}} />}
            calendarIcon={<Calendar disabled={disabled} />}
            value={date}
            format={dateFormat}
            onChange={changeData}
          />
        </div>
      </div>
    ),
    [dateLabel, disabled, dateFormat, changeData, isCalendarOpen],
  )

  const TimeField: React.FC<{
    time: TimePickerValue
  }> = useCallback(
    ({ time }): JSX.Element => (
      <div ref={timeRef} className={dateTimeWrapperClasses}>
        <div className={pickerClasses}>
          <Label className="input-wrapper__label" label={timeLabel} />
          <TimePicker
            disableClock
            clearIcon={<CloseIcon onClick={() => {}} />}
            value={time}
            format={timeFormat}
            onChange={changeTime}
          />
        </div>
      </div>
    ),
    [changeTime, timeFormat, timeLabel],
  )

  switch (format) {
    case 'date': {
      return <DataField date={date} />
    }
    case 'time': {
      return <TimeField time={time} />
    }
    case 'datetime': {
      return (
        <div className={dateTimeClasses}>
          <DataField date={date} />
          <TimeField time={time} />
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default DateTimePickerField
