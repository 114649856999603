import React, { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'
import { connect, ConnectedProps } from 'react-redux'

import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'
import AuthFieldNames from '$constants/common/forms/auth/fields/AuthFieldsName'
import popupsID from '$constants/common/popupsID/popupsID'
import routePath from '$constants/common/routePath/routePath'

import { hidePopup } from '$store/slices/popup'
import { signIn } from '$store/slices/user/thunks/user'
import { AppDispatch, RootState } from '$store/store'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'
import useValidators from '$hooks/useValidators/useValidators'

import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'
import BottomLinks from '$components/ui/forms/BottomLinks'

import { popupLink } from '$utils/link'
import { goToAccountRoom } from '$utils/navigation/account'

import FormikWrapper from '../../../constructor/FormConstructor/formikConstructor'
import initializeValue from '../../../utils/InitializeFormikValues'

const SignInForms: React.FC<Props> = ({
  viewConfig,
  config,
  signInAction,
  hidePopupAction,
}) => {
  const dictionary = useLanguageDictionary()
  const { isRequired, isValidEmail, maxLength, minLength } = useValidators()
  const router = useRouter()
  const goToPersonal = useCallback(() => {
    hidePopupAction()
    goToAccountRoom(router)
  }, [hidePopupAction, router])

  const initialValues = useMemo(
    () =>
      initializeValue([
        config === 'email' ? AuthFieldNames.email : AuthFieldNames.phone,
        AuthFieldNames.password,
      ])(),
    [],
  )

  const onLogin = useFormSubmitHandler<typeof initialValues>([], (values) => {
    if (values.phone) {
      values.phone = `7${values.phone}`
    }

    return signInAction(values).then(() => {
      goToPersonal()
    })
  })

  const postPromoHandler = usePromoStatus(false)
  postPromoHandler()

  return (
    <>
      <FormikWrapper
        onSubmit={onLogin}
        name="signin"
        initialValues={initialValues}
        submitButtonName={dictionary.button.signIn}
        dataTest="sign-in-button"
      >
        {config === 'email' ? (
          <TextInputField
            name={authFieldCollection.email.name}
            placeholder={dictionary.fields.email}
            validate={validatorCombiner([
              isRequired,
              isValidEmail,
              maxLength(255),
            ])}
            inputMode="email"
            data-test="email-input"
          />
        ) : (
          <TextInputField
            name={authFieldCollection.phone.name}
            data-test="phone-number-input"
            type={authFieldCollection.phone.type}
            inputMode="tel"
            placeholder={dictionary.fields.phone}
            mask={authFieldCollection.phone.mask}
            label={authFieldCollection.phone.label}
            validate={validatorCombiner([
              isRequired,
              minLength(10, /[\D]+/g, 1),
            ])}
          />
        )}

        <TextInputField
          name={authFieldCollection.password.name}
          placeholder={dictionary.fields.password}
          type={authFieldCollection.password.type}
          validate={validatorCombiner([isRequired, minLength(8)])}
          data-test="pass-input"
        />
      </FormikWrapper>

      {postPromoHandler() ? (
        <BottomLinks
          links={[
            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.restore_password)
                  : routePath.restorePassword,
              name: dictionary.links.restorePassword,
            },
          ]}
        />
      ) : (
        <BottomLinks
          links={[
            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.sign_up)
                  : routePath.reg,
              name: dictionary.links.signUp,
            },
            {
              href:
                viewConfig === 'modal'
                  ? popupLink(popupsID.restore_password)
                  : routePath.restorePassword,
              name: dictionary.links.restorePassword,
            },
          ]}
        />
      )}
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    isUserUpload: !!state.user,
    viewConfig: state.config.auth?.viewTemplate,
    config: state.config.auth.signInConfig.login_by,
  }),
  (dispatch: AppDispatch) => ({
    signInAction: async (value: any) => dispatch(signIn(value)).unwrap(),
    hidePopupAction: () => {
      dispatch(hidePopup())
    },
  }),
)

export default connector(SignInForms)
