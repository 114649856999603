import React from 'react'

import { useDispatch } from 'react-redux'

import kladrApi from '$api/kladr/kladrApi'
import pbAxios from '$api/promobuilding/instance'

import authFieldCollection from '$constants/common/forms/auth/fields/AuthFieldsConstant'

import { showPopup } from '$store/slices/popup'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useValidators from '$hooks/useValidators/useValidators'

import ImageUploadFileField from '$components/form/fields/formikField/field/ImageUploadField/ImageUploadField'
import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import AsyncSelectField from '$components/form/formik/fields/AsynchSelectField/AsynchSelectField'
import CheckBoxField from '$components/form/formik/fields/CheckBoxField'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'

import { CustomFormPropsInterface } from './type'

import SyncSelectField from '../../fields/SynchSelectField/SynchSelectField'

const CustomForm: React.FC<CustomFormPropsInterface> = ({
  form: { fields, submit_button_text, id, message_on_success, slug },
}) => {
  const dispatch = useDispatch()
  const initializeValue = fields?.reduce((acc, field) => {
    const value =
      field.type === 'checkbox' ? field.defaults_to_checked !== '0' : ''
    // field.type === 'checkbox' ? field.defaults_to_checked !== '0' : (field.type === 'region' || field.type === 'city') ? null : ''
    acc[`${field.name}`] = value

    return acc
  }, {} as any)

  const telInput = fields.find((field) => field.type === 'phone')?.name

  const sendForm = useFormSubmitHandler<typeof initializeValue>(
    [],
    (values, { resetForm }) => {
      const data = new FormData()

      Object.entries(values).forEach(([name, value]) => {
        const _value = value as any

        if (_value.length && typeof _value !== 'string') {
          Array.from(_value).forEach((val) => {
            data.append(`${name}`, val as any)
          })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (name === telInput) {
            data.append(name, `+7${_value}`)
          } else {
            data.append(name, _value)
          }

          // console.log(name)

          // console.log(_value)
        }
      })
      return pbAxios.post(`/services/custom-form/${id}`, data).then(() => {
        resetForm()
        dispatch(
          showPopup({
            message: message_on_success,
            type: 'success',
          }),
        )
      })
    },
  )

  const {
    isRequired,
    isValidEmail,
    minLength,
    maxLength,
    isTestRegular,
    minNumbValue,
    maxNumbValue,
    isInteger,
  } = useValidators()

  return (
    <FormikConstructor
      direction={slug}
      initialValues={initializeValue}
      onSubmit={sendForm}
      name="custom_form"
      type="custom"
      submitButtonName={submit_button_text ?? 'Отправить'}
    >
      {fields.map((field) => {
        const validators = []
        if (field.required === '1') {
          validators.push(isRequired)
        }
        switch (field.type) {
          case 'text': {
            const fieldValidator = [...validators]
            if (field.regex) {
              fieldValidator.push(
                isTestRegular(new RegExp(field.regex.slice(1).slice(0, -1))),
              )
            }
            if (field.min) {
              fieldValidator.push(minLength(Number(field.min)))
            }
            if (field.max) {
              fieldValidator.push(maxLength(Number(field.max)))
            }
            return (
              <TextInputField
                key={field.name}
                name={field.name}
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                validate={validatorCombiner(fieldValidator)}
                type={field.type}
              />
            )
          }
          case 'email': {
            const emailValid = [...validators, isValidEmail]
            return (
              <TextInputField
                key={field.name}
                novalidate
                name={field.name}
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                validate={validatorCombiner(emailValid)}
                type={field.type}
              />
            )
          }
          case 'phone': {
            const phoneValidateArray = [minLength(10, /[\D]+/g, 1)]
            const phoneValid = [...validators, ...phoneValidateArray]
            return (
              <TextInputField
                key={field.name}
                name={field.name}
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                mask={authFieldCollection.phone.mask}
                validate={validatorCombiner(phoneValid)}
                type={field.type}
              />
            )
          }
          case 'checkbox': {
            return (
              <CheckBoxField
                key={field.name}
                name={field.name}
                validate={validatorCombiner(validators)}
              >
                <p dangerouslySetInnerHTML={{ __html: field.checkbox_text }} />
              </CheckBoxField>
            )
          }
          case 'upload': {
            const maxFileSize = field.max || undefined
            const allowFormat = JSON.parse(field.file_extensions)
            return (
              <ImageUploadFileField
                key={field.name}
                validate={validatorCombiner(validators)}
                label={field.title}
                maxFileSize={maxFileSize}
                allowFileFormat={allowFormat}
                format={field.upload_type}
                name={field.name}
              />
            )
          }

          case 'number': {
            const fieldValidator = [...validators]
            if (field.min) {
              fieldValidator.push(minNumbValue(Number(field.min)))
            }
            if (field.max) {
              fieldValidator.push(maxNumbValue(Number(field.max)))
            }
            if (field.only_integer === '1') {
              fieldValidator.push(isInteger)
            }
            return (
              <TextInputField
                key={field.name}
                type="number"
                name={field.name}
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                inputMode={field.only_integer === '1' ? 'numeric' : 'decimal'}
                validate={validatorCombiner(fieldValidator)}
              />
            )
          }
          case 'region': {
            return (
              <AsyncSelectField
                key={field.name}
                name={field.name}
                loadOptions={kladrApi.getRegion}
                data-test="region-input"
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                validate={validatorCombiner(validators)}
                isClearable={false}
                isCustomForm
              />
            )
          }
          case 'city': {
            return (
              <AsyncSelectField
                key={field.name}
                name={field.name}
                loadOptions={kladrApi.getCity}
                data-test="region-input"
                label={field.placeholder && field.title}
                placeholder={field.placeholder || field.title}
                validate={validatorCombiner(validators)}
                isClearable={false}
                isCustomForm
              />
            )
          }
          case 'select': {
            return (
              <SyncSelectField
                key={field.name}
                name={field.name}
                placeholder={field.title}
                isSearchable={field.search_allowed === '1'}
                options={field.options.map(({ value }) => ({
                  label: value,
                  value,
                }))}
                validate={validatorCombiner(validators)}
                isClearable={false}
              />
            )
          }
          default: {
            return null
          }
        }
      })}
    </FormikConstructor>
  )
}

export default CustomForm
