import React from 'react'

import { useAppSelector } from '$store/hooks'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import QRScannerUploadCheckForm from '$components/form/formik/forms/uploadCheck/QrScannerUploadCheck/QRScannerUploadCheckForm'

const QRUploadCheckPopup: React.FC = () => {
  const popupState = useAppSelector((state) => state.popup.activeState)
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.qrUploadCheck}
      </h2>
      {popupState && <QRScannerUploadCheckForm />}
    </div>
  )
}

export default QRUploadCheckPopup
