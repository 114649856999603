import { useEffect } from 'react'

import { useFormikContext } from 'formik'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import PopupId from '$constants/common/popupsID/popupsID'

import { useAppSelector } from '$store/hooks'
import { setConfirmInviteCode } from '$store/slices/config'
import { hidePopup } from '$store/slices/popup'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import useValidators from '$hooks/useValidators/useValidators'

import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import TextInputField from '$components/form/formik/fields/TextInputField'
import validatorCombiner from '$components/form/validators/validatorCombiner'
import BottomLinks from '$components/ui/forms/BottomLinks'

const InviteCodeForms = () => {
  const dictionary = useLanguageDictionary()
  const dispatch = useDispatch()
  const { query } = useRouter()
  const inviteSharingLink = useAppSelector(
    (state) => state.config.motivational_program?.sharingLink,
  )

  const inviteQuery = inviteSharingLink?.queryParamName
    ? inviteSharingLink?.queryParamName
    : 'invite'
  const { [inviteQuery]: invite } = query

  const inviteCode = invite

  const initialValues = {
    motivational_invite_code: typeof inviteCode === 'string' ? inviteCode : '',
  }

  const onSubmit = useFormSubmitHandler<typeof initialValues>([], (value) =>
    pbApi.confirmInviteCode(value).then(() => {
      dispatch(hidePopup())
      dispatch(setConfirmInviteCode(value.motivational_invite_code))
      // const pathWithInvite =
      // push('/reg', '/reg', { shallow: true })
    }),
  )

  const { isRequired, latinAndDigit } = useValidators()

  const InviteFormChildren = () => {
    const { submitForm } = useFormikContext()

    useEffect(() => {
      if (inviteCode) {
        submitForm()
      }
    }, [inviteCode])

    return (
      <TextInputField
        placeholder={dictionary.fields.inviteCode}
        validate={validatorCombiner([isRequired, latinAndDigit])}
        name="motivational_invite_code"
      />
    )
  }

  return (
    <>
      <FormikConstructor
        submitButtonName={dictionary.button.confirm}
        initialValues={initialValues}
        onSubmit={onSubmit}
        name="inviteCode"
      >
        <InviteFormChildren />
      </FormikConstructor>
      <BottomLinks
        links={[
          {
            href: '/application',
            name: 'У меня нет пригласительного кода',
          },
          {
            href: `/reg?popupid=${PopupId.feedback}`,
            name: 'Задать вопрос',
          },
        ]}
      />
    </>
  )
}

export default InviteCodeForms
