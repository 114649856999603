import React from 'react'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import PhotoUploadCheckForm from '$components/form/formik/forms/uploadCheck/PhotoUploadCheckForm/PhotoUploadCheckForm'

const PhotoUploadCheckPopup: React.FC = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup">
      <h2 className="popup__title popup__title_md">
        {dictionary.header.photoUploadCheck}
      </h2>
      <PhotoUploadCheckForm />
    </div>
  )
}

export default PhotoUploadCheckPopup
