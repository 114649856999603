import { useEffect, useState } from 'react'

import { Document, Page, pdfjs } from 'react-pdf'
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types'
import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import AgreementRules from '$components/form/fields/other/confirmation/AgreementRules'
import Preloader from '$components/layoutParts/Preloader/Preloader'
import Button from '$components/ui/buttons/RootButton/Button'
import RootCheckBox from '$components/ui/fields/RootCheckBox'

import { isDevBuildMode } from '$utils/env'

import TestPdf from '$assets/files/test.pdf'

import s from './style.module.scss'

// import ExamplePdf from '$assets/files/example.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const ReceiptPopup = () => {
  const dictionary = useLanguageDictionary()
  const { popupData } = useAppSelector((store) => store.popup.popupConfig)

  const [confirmRules, setConfirmRules] = useState(false)

  const [receipt, setReceipt] = useState<{ src: string; id: number } | null>(
    null,
  )
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [pdfOptions, setPdfOptions] = useState<DocumentCallback | null>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(popupData.orderId
      ? pbApi.getOrderReceipt(popupData.orderId)
      : pbApi.getReceipt(popupData.rewardId)
    )
      .then(
        ({
          data: {
            receipt: { pdf, id, signed_pdf },
          },
        }) => {
          setReceipt(
            isDevBuildMode()
              ? { src: TestPdf, id }
              : { src: signed_pdf ?? pdf, id },
          )
          if (signed_pdf) {
            setIsSigned(true)
          }
        },
      )
      .catch((err) => {
        dispatch(
          showPopup({
            type: 'error',
            title: 'Ошибка',
            message: err.response.data.errors,
          }),
        )
      })
  }, [])

  return (
    <div className={s['document-popup']}>
      <h2 className="popup__title popup__title_md">
        {dictionary.header.consent}
      </h2>
      {/* @ts-ignore */}
      <Document
        loading=""
        error=""
        noData=""
        className={s['pdf-container']}
        onLoadSuccess={(arg) => {
          setPdfOptions(arg)
          setIsLoaded(true)
        }}
        renderMode="canvas"
        file={receipt?.src}
      >
        {/* @ts-ignore */}
        <Page
          renderAnnotationLayer={false}
          renderTextLayer={false}
          renderMode="canvas"
          pageNumber={pageNumber}
        />
      </Document>
      {isLoaded ? (
        <div className={s['services-block']}>
          {pdfOptions && pdfOptions._pdfInfo.numPages > 0 && (
            <div className={s['page-navigation']}>
              <p style={{ fontSize: 14 }}>Страница: {pageNumber}</p>
              <div className={s['page-navigation__navigator']}>
                <button
                  className={s['page-navigation__btn']}
                  onClick={() => {
                    setPageNumber((page) => {
                      if (page === 1) {
                        return pageNumber
                      }
                      return --page
                    })
                  }}
                >
                  {'<'}
                </button>
                <button
                  className={s['page-navigation__btn']}
                  onClick={() => {
                    setPageNumber((page) => {
                      if (page === pdfOptions._pdfInfo.numPages) {
                        return page
                      }
                      return ++page
                    })
                  }}
                >
                  {'<'}
                </button>
              </div>
            </div>
          )}
          {popupData.isSigned || isSigned ? (
            !popupData.isClaimed ? (
              <div>
                <a
                  href={popupData.certificateLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {}
                  <Button
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }}
                  >
                    Получить приз
                  </Button>
                </a>
              </div>
            ) : (
              <a
                target="_blank"
                href={popupData.provse_link ?? ''}
                style={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'block',
                  marginTop: 10,
                }}
              >
                {`Сертификат "Провсё"`}
              </a>
            )
          ) : (
            <div className={s['rules-block']}>
              <RootCheckBox
                name=""
                checked={confirmRules}
                hiddenCheckBox={false}
                onChange={(value: any) => {
                  setConfirmRules(value.target.checked)
                }}
              >
                <AgreementRules withConfidentialPolicy />
              </RootCheckBox>
              <div style={{ maxWidth: 200, margin: '20px auto' }}>
                <Button
                  onClick={() => {
                    dispatch(
                      showPopup({
                        popupData: {
                          ...popupData,
                          receiptId: receipt?.id,
                        },
                        popupId: 'consent',
                      }),
                    )
                  }}
                  disabled={!confirmRules || !isLoaded}
                >
                  {dictionary.button.signed}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Preloader />
        </div>
      )}
    </div>
  )
}

export default ReceiptPopup
