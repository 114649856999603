import React, { useCallback, useState, useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'

import popupsID, { PopupType } from '$constants/common/popupsID/popupsID'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'

import Button from '$components/ui/buttons/RootButton/Button'

import classes from './UploadCheckPopup.module.scss'

const UploadCheckPopup: React.FC<Props> = ({
  showPopupAction,
  uploadMethods,
}) => {
  const dictionary = useLanguageDictionary()

  const uploadMethodsLabel = useMemo(
    () => ({
      manual: {
        name: dictionary.button.handInsert.toUpperCase(),
        popup: popupsID.upload_manual_check,
        datatest: 'manual-button',
      },
      photo: {
        name: dictionary.button.photoUpload.toUpperCase(),
        popup: popupsID.upload_photo_check,
        datatest: 'photo-button',
      },
      qr: {
        name: dictionary.button.qrScan.toUpperCase(),
        popup: popupsID.upload_qr_check,
        datatest: 'qr-button',
      },
    }),
    [dictionary],
  )

  const [isNotHaveMediaDevice, setNoMediaDevice] = useState(false)
  const isNotEmptyUploadMethods = !!uploadMethods.length
  const openUploadCheck = useCallback(
    (popupId: PopupType, method: 'manual' | 'qr' | 'photo') => async () => {
      if (method === 'qr') {
        const isHaveMedia = await navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then(() => {
            setNoMediaDevice(false)
            return true
          })
          .catch(() => {
            setNoMediaDevice(true)
            return false
          })
        if (!isHaveMedia) {
          return
        }
      }
      showPopupAction({
        popupId,
      })
    },
    [showPopupAction],
  )

  const promoStatusHandler = usePromoStatus(true, {
    title: 'Прием чеков окончен',
  })
  if (promoStatusHandler()) {
    return null
  }

  const Buttons = uploadMethods.map((item, index) => (
    <div key={`upload-methods-${index}`} className={classes['block']}>
      <Button
        disabled={item === 'qr' ? isNotHaveMediaDevice : false}
        dataTest={uploadMethodsLabel[`${item}`].datatest}
        onClick={openUploadCheck(uploadMethodsLabel[`${item}`].popup, item)}
      >
        {uploadMethodsLabel[`${item}`].name}
      </Button>
    </div>
  ))

  return (
    <div className="form-popup">
      <div>
        {isNotEmptyUploadMethods ? (
          <>
            <h2 className="popup__title popup__title_md">
              {dictionary.header.uploadMethod}
            </h2>
            {Buttons}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            Не включен ни один из возможных способов загрузки чеков.
          </div>
        )}
      </div>
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    uploadMethods: state.config.check?.checkUploadMethods!,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(UploadCheckPopup)
