import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import classNames from 'classnames'
import { Formik, Form, FormikProps } from 'formik'

import { useAppSelector } from '$store/hooks'

import Preloader from '$components/layoutParts/Preloader/Preloader'
import Button from '$components/ui/buttons/RootButton/Button'

import scrollToErrorField from '$utils/scrollToErrorField/scrollToErrorField'

import V2Recaptcha from '$/other/captcha/V2Recaptcha'
import V3Recaptcha from '$/other/captcha/V3Recaptcha'

import { BasicFormPropsInterface } from '../../../types/types'

import s from './formikConstructor.module.scss'

type FormikWrapperConstructorType<T> = PropsWithChildren<
  BasicFormPropsInterface<T>
>

const fieldsContainerClasses = classNames(s['fields-container'])

const CustomFormWrapper: React.FC<
  FormikProps<any> & {
    children: React.ReactNode
    submitButtonName: string
    type?: string
    direction: string
    disabled?: boolean
    dataTest?: string
  }
> = ({
  touched,
  errors,
  children,
  isSubmitting,
  submitButtonName,
  type = 'default',
  disabled,
  dataTest,
}) => {
  const buttonContainerClasses = classNames(
    s['button-container'],
    type && s['type'],
  )

  const ref = useRef<HTMLDivElement>(null)

  const errorsFields = Object.keys(errors)
  const touchedFields = Object.keys(touched)

  const isNotValid =
    errorsFields.filter((item) => touchedFields.includes(item)).length > 0

  useEffect(() => {
    if (ref) {
      const elem = document.querySelector<HTMLInputElement>('form')
      elem?.focus()
    }
  }, [])

  useEffect(() => {
    scrollToErrorField(isNotValid, ref)
  }, [isNotValid])

  return (
    <Form className={s['form']}>
      <div className={s[type]}>
        <div ref={ref} className={fieldsContainerClasses}>
          {children}
        </div>
        <div
          style={{ display: submitButtonName ? 'block' : 'none' }}
          className={buttonContainerClasses}
        >
          <Button
            dataTest={dataTest}
            type="submit"
            disabled={isNotValid || isSubmitting || disabled}
          >
            {!isSubmitting ? submitButtonName : <Preloader />}
          </Button>
        </div>
      </div>
    </Form>
  )
}

const FormikConstructor = <T,>({
  initialValues,
  submitButtonName,
  children,
  disabled,
  direction = 'vertical',
  dataTest,
  onSubmit,
  type,
  name,
}: FormikWrapperConstructorType<T>) => {
  const recaptchaRef = useRef<any>(null)
  const recaptchaV3Ref = useRef<
    () => Promise<{
      recaptcha_response: ''
    }>
  >(async () => ({
    recaptcha_response: '',
  }))

  const { forms, ...recaptcha } = useAppSelector(
    (state) => state.config.site?.recaptcha,
  ) ?? {
    forms: '',
    is_enabled: false,
    site_key: '',
    version: null,
    v2_type: 'invisible',
  }

  const isRecaptchaEnabled = forms.indexOf(name) >= 0

  const getCaptchaToken = useCallback(
    async <T,>(value: T) => {
      if (isRecaptchaEnabled && recaptcha.is_enabled) {
        if (recaptcha?.version === 'v2') {
          if (recaptcha?.v2_type === 'normal') {
            const recaptchaResponse = recaptchaRef.current.getValue()
            recaptchaRef.current.reset()
            return {
              ...value,
              recaptcha_response: recaptchaResponse,
            }
          }
          if (recaptcha?.v2_type === 'invisible') {
            recaptchaRef.current.reset()
            const recaptchaResponse = await recaptchaRef.current.executeAsync()
            return {
              ...value,
              recaptcha_response: recaptchaResponse,
            }
          }
        }
        if (recaptcha?.version === 'v3') {
          const token = await recaptchaV3Ref.current()
          return {
            ...value,
            ...token,
          }
        }
      }
      return {
        ...value,
        recaptcha_response: '',
      }
    },
    [recaptcha?.v2_type, recaptcha?.version],
  )

  const submitHandler: any = useMemo(
    () => onSubmit(getCaptchaToken),
    [getCaptchaToken, onSubmit],
  )

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      {(props) => (
        <CustomFormWrapper
          {...props}
          dataTest={dataTest}
          disabled={disabled}
          direction={direction}
          submitButtonName={submitButtonName ?? ''}
          type={type}
        >
          {children}
          {recaptcha.is_enabled && forms.indexOf(name) >= 0 && (
            <div>
              {recaptcha?.version === 'v2' && (
                <V2Recaptcha {...recaptcha} recaptchaRef={recaptchaRef} />
              )}
              {recaptcha?.version === 'v3' && (
                <V3Recaptcha recaptchaRef={recaptchaV3Ref} />
              )}
            </div>
          )}
        </CustomFormWrapper>
      )}
    </Formik>
  )
}

export default FormikConstructor
