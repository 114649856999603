import React from 'react'

// TYPE
import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import ManualUploadCheck from '$components/form/formik/forms/uploadCheck/ManualUploadForm/ManualUploadCheckForm'

const ManualUploadCheckPopup: React.FC = () => {
  const dictionary = useLanguageDictionary()
  return (
    <div className="form-popup" style={{ maxHeight: 540 }}>
      <h2 className="popup__title popup__title_md">
        {dictionary.header.handInsertCheck}
      </h2>
      <ManualUploadCheck />
    </div>
  )
}

export default ManualUploadCheckPopup
