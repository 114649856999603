import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import NewPasswordForm from '$components/form/formik/forms/auth/NewPassword/NewPasswordForm'
import ClipSpinner from '$components/ui/spinners/ClipSpinner/ClipSpinner'

import onlyStringParam from '$utils/onlyStringParam'

const NewPasswordPopup = () => {
  const router = useRouter()
  const dictionary = useLanguageDictionary()
  const [state, setState] = useState(false)

  useEffect(() => {
    const token = onlyStringParam(router.query['token'])
    if (token) {
      setState(true)
    }
  }, [])

  return (
    <div className="form-popup">
      {state ? (
        <>
          <h2 className="popup__title popup__title_md">
            {dictionary.header.newPassword}
          </h2>
          <NewPasswordForm />
        </>
      ) : (
        <div style={{ margin: '0 auto' }}>
          <ClipSpinner size={75} />
        </div>
      )}
    </div>
  )
}

export default NewPasswordPopup
