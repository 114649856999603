import { useAppSelector } from '$store/hooks'

import InviteCodeForms from '$components/form/formik/forms/auth/InviteCodeForm/InviteCodeForm'
import SignUpForms from '$components/form/formik/forms/auth/SignUpForms/SignUpForms'

const SignUpFormBlock = () => {
  const motivationProgramConfig = useAppSelector(
    (store) => store.config.auth?.motivationalProgramConfig,
  )

  const { code } = useAppSelector((store) => store.config.inviteCode)

  if (
    !motivationProgramConfig ||
    motivationProgramConfig.invite_code_show_config === 'inside_registration' ||
    motivationProgramConfig.invite_code_show_config === null ||
    code
  ) {
    return <SignUpForms />
  }

  return <InviteCodeForms />
}

export default SignUpFormBlock
